<template>
  <v-card flat class="my-6 mx-9 rounded-lg" color="#f5f7f7">
    <div class="network-wrapper">
      <v-btn
        class="network-control-fit"
        x-small
        @click="fit()"
        color="#616161"
        dark
        >fit</v-btn
      >
      <div id="graph2d" class="mt-2"></div>
    </div>
  </v-card>
</template>

<script>
import ForceGraph from "force-graph";
import config from "@/assets/config";

export default {
  props: ["details", "show"],

  mounted() {},

  data() {
    return {
      intialized: false,
    };
  },

  computed: {
    network() {
      return this.details.network;
    },
  },

  methods: {
    createNetwork() {
      const el = document.getElementById("graph2d");

      // initialize
      this.graph = ForceGraph()(el);

      const { clientWidth: width } = el;

      const height = this.details.height;

      this.graph
        .height(height)
        .width(width)
        .nodeCanvasObjectMode(() => "after")
        .linkDirectionalParticles(1);

      this.graph.nodeCanvasObject((node, ctx, globalScale) => {
        // outer circle
        if (node.outerCircle) {
          ctx.beginPath();
          ctx.arc(node.x, node.y, 10, 0, 2 * Math.PI, false);
          ctx.strokeStyle = node.outerCircle;
          ctx.lineWidth = 1.5 / globalScale;
          ctx.stroke();
        }

        // icon
        ctx.font = "3px FontAwesome";
        ctx.fillStyle = "white";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(node.icon_code, node.x, node.y);

        // label
        ctx.font = `3px Sans-Serif`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(node.label, node.x, node.y + 7);
      });

      // style nodes
      this.setIconStyles(this.network.nodes);

      // set data
      this.graph.graphData(this.network);
    },

    fit() {
      this.graph.zoomToFit(250, 50);
    },

    setIconStyles(nodes) {
      const node_styles = config.node_styles;

      nodes.forEach((d) => {
        const node_style = node_styles[d.node_type];

        if (node_style) {
          d.color = node_style.color;
          d.icon_code = node_style.icon_code;
          d.icon_color = node_style.icon_color;
        } else {
          d.color = node_styles["default"].color;
          d.icon_code = node_styles["default"].icon_code;
          d.icon_color = node_styles["default"].icon_color;
        }
      });
    },
  },

  watch: {
    show(val) {
      if (val && !this.intialized) {
        this.createNetwork();

        setTimeout(() => {
          this.graph.zoomToFit(250, 20);
        }, 1500);

        this.intialized = true;

        console.log("network intialized with show:", this.show);
      }

      console.log("network viewer is open: ", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.network-wrapper {
  position: relative;
}

.network-control-fit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
</style>